import React, { useState, useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled, { createGlobalStyle } from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "../components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
`;

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  background-color: #6415FF; /* Définir la couleur de fond bleue */  
  padding-bottom: ${({ scrolled }) => (scrolled ? "0" : "2rem")}; /* Appliquer pb-0 lorsque scrolled */
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

export default () => {
  const [scrolled, setScrolled] = useState(true);
  const [showPhone, setShowPhone] = useState(false); // État pour afficher/masquer le téléphone

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const togglePhoneVisibility = () => {
    setShowPhone(!showPhone); // Alterner la visibilité du téléphone
  };

  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/">
        Accueil
      </NavLink>
      <NavLink href="/#services">
        Nos Services
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink href="/contact-us">
        Contactez-nous
      </PrimaryLink>
    </NavLinks>
  ];

  return (
    <AnimationRevealPage>
      <GlobalStyle />
      <StyledHeader links={navLinks} scrolled={scrolled} />
      <ContactUsForm />
      <ContactDetails
        cards={[
          {
            title: "Sitki Dev",
            description: (
              <>
                <Email>contact@sitki.dev</Email>
                <div>
                  <button onClick={togglePhoneVisibility} tw="text-primary-300 underline">
                    {showPhone ? "Masquer le numéro" : "Afficher le numéro"}
                  </button>
                  {showPhone && <Phone>07 83 80 02 46</Phone>}
                </div>
              </>
            )
          }
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
