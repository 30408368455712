import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading } from "components/misc/Headings.js";
import { PrimaryLink as PrimaryLinkBase } from "components/misc/Links.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as TimeIcon } from "feather-icons/dist/icons/clock.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const ThreeColumn = tw.div`flex flex-wrap`;
const Column = tw.div`xl:mr-12 xl:last:mr-0`;
const HeadingColumn = styled(Column)(props => [
  tw`w-full xl:w-5/12`,
  props.textOnLeft ? tw`xl:order-first` : tw`xl:order-last xl:ml-12 xl:mr-0`
]);
const CardColumn = tw(Column)`w-full md:w-1/2 xl:w-3/12 mt-16 xl:mt-0 xl:last:ml-auto overflow-hidden`;

const HeadingInfoContainer = tw.div`text-center xl:text-left max-w-lg xl:max-w-none mx-auto xl:mx-0`;
const HeadingTitle = tw(SectionHeading)`mt-4 xl:text-left leading-tight`;
const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-8`;
const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center xl:justify-start items-center mt-8 text-lg`}
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
`;

const Card = styled.div`
  ${tw`mx-auto xl:mx-0 xl:ml-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs flex flex-col items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    
    img {
      ${tw`w-8 h-8`}
    }
      overflow: hidden;
  }
`;

const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-80 bg-cover bg-center rounded`
]);

const CardText = tw.div`mt-4`;

const CardHeader = tw.div`flex justify-between items-center`;
const CardCompany = tw.div`text-primary-500 font-bold text-lg`;
const CardType = tw.div`font-semibold text-sm text-gray-600`;

const CardTitle = tw.h5`text-xl mt-4 font-bold`;

const CardMeta = styled.div`
  ${tw`flex flex-row flex-wrap justify-center sm:items-center font-semibold tracking-wide text-gray-600 uppercase text-xs`}
`;

const CardMetaFeature = styled.div`
  ${tw`flex items-center mt-4 mr-4 last:mr-0`}
  svg {
    ${tw`w-5 h-5 mr-1`}
  }
`;
const CardAction = tw(PrimaryButtonBase)`w-full mt-6`;

export default ({
  subheading = "Nos Réalisations",
  headingHtmlComponent = (
    <>
      Nous avons réalisé des <span tw="text-primary-500">projets exceptionnels</span>.
      </>
  ),
  description = "Découvrez nos projets de création de sites web sur mesure, conçus pour répondre aux besoins uniques de chaque client et offrir une expérience en ligne optimale.",
  linkText = "Voir tous les projets",
  cardLinkText = "Visitez le site",
  textOnLeft = false
}) => {
  const cards = [
    {
      url: "https://sitki.dev/",
      company: "Sitki.dev",
        type: "Création de site web",
        title: "Refonte du site web de Sitki.dev avec un design personnalisé",
        // durationText: "90 Days Campaign",
      // locationText: "New York"
    },
    {
      url: "https://sousuncielditalie.com", // Remplacez par le lien de la deuxième page que vous voulez intégrer
      company: "Sous un Ciel d'Italie",
        type: "Site vitrine et SEO",
        title: "Création du site vitrine et optimisation SEO pour Sous un Ciel d'Italie",
      //   durationText: "180 Day Campaign",
      // locationText: "Palo Alto"
    }
  ];
  return (
    <Container>
      <Content>
        <ThreeColumn>
          <HeadingColumn textOnLeft={textOnLeft}>
            <HeadingInfoContainer>
              <Subheading>{subheading}</Subheading>
              <HeadingTitle>{headingHtmlComponent}</HeadingTitle>
              <HeadingDescription>{description}</HeadingDescription>
              <PrimaryLink>
                {linkText} <ArrowRightIcon />
              </PrimaryLink>
            </HeadingInfoContainer>
          </HeadingColumn>
          {cards.map((card, index) => (
            <CardColumn key={index}>
            <Card>
              {/* Utilisation d'un iframe pour intégrer le site au lieu d'une image */}
              <iframe
            src={card.url}
            width="100%"
            height="400px" // Ajustez la hauteur selon vos besoins
            style={{ border: 'none', overflow: 'hidden' }}
            title={card.title}
            scrolling="yes" // Désactive le défilement
          />
              <CardText>
                <CardHeader>
                  <CardCompany>{card.company}</CardCompany>
                  <CardType>{card.type}</CardType>
                </CardHeader>
                <CardTitle>{card.title}</CardTitle>
                <CardMeta>
                  <CardMetaFeature>
                    {/* <TimeIcon /> {card.durationText} */}
                  </CardMetaFeature>
                  <CardMetaFeature>
                    {/* <LocationIcon /> {card.locationText} */}
                  </CardMetaFeature>
                </CardMeta>
                <CardAction
                  onClick={() => window.open(card.url, "_blank")}
                >
                  {cardLinkText}
                </CardAction>
              </CardText>
            </Card>
          </CardColumn>
          ))}
        </ThreeColumn>
      </Content>
    </Container>
  );
};
