import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com"; // Assurez-vous d'avoir installé emailjs-com
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw.div`text-center md:text-left`;
const Heading = tw.h1`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`};
`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

const ContactForm = ({
  subheading = "Contactez-nous",
  heading = <>N'hésitez pas à <span tw="text-primary-500">nous contacter</span><wbr/>.</>,
  description = "Pour toute question ou demande, nous sommes là pour vous aider. Remplissez le formulaire ci-dessous et nous vous répondrons rapidement.",
  submitButtonText = "Envoyer",
  textOnLeft = true,
}) => {
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    subject: '',
    message: '',
  });
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    emailjs.init("lrOmZlm-ZlSuwUZ2j"); // Remplacez par votre clé publique
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);
    try {
      // Envoi des données via EmailJS avec le template et les valeurs du formulaire
      await emailjs.send("service_nhs06sa", "template_vstkmqk", {
        from_name: formData.name,
        from_email: formData.email,
        to_name: "Your Team", // Nom du destinataire
        subject: formData.subject,
        message: formData.message,
      });

      alert("Email envoyé avec succès !");
      setFormData({ email: '', name: '', subject: '', message: '' });
    } catch (error) {
      console.error("Erreur lors de l'envoi :", error);
      alert("Erreur lors de l'envoi de l'email. Veuillez réessayer plus tard.");
    } finally {
      setIsSending(false);
    }
  };

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form onSubmit={handleSubmit}>
              <Input
                type="email"
                name="email"
                placeholder="Votre adresse e-mail"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <Input
                type="text"
                name="name"
                placeholder="Nom complet"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <Input
                type="text"
                name="subject"
                placeholder="Sujet"
                value={formData.subject}
                onChange={handleChange}
                required
              />
              <Textarea
                name="message"
                placeholder="Votre message ici"
                value={formData.message}
                onChange={handleChange}
                required
              />
              <SubmitButton type="submit" disabled={isSending}>
                {isSending ? "Envoi en cours..." : submitButtonText}
              </SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};

export default ContactForm;
