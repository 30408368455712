import React, { useState, useEffect } from "react";

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [customize, setCustomize] = useState(false);
  const [preferences, setPreferences] = useState({
    necessary: true,
    analytics: false,
    marketing: false,
  });

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (!cookiesAccepted) {
      setShowBanner(true);
    }
  }, []);

  const handleAcceptAll = () => {
    localStorage.setItem(
      "cookiesAccepted",
      JSON.stringify({
        necessary: true,
        analytics: true,
        marketing: true,
      })
    );
    setShowBanner(false);
  };

  const handleRefuseAll = () => {
    localStorage.setItem(
      "cookiesAccepted",
      JSON.stringify({
        necessary: true,
        analytics: false,
        marketing: false,
      })
    );
    setShowBanner(false);
  };

  const handleSavePreferences = () => {
    localStorage.setItem("cookiesAccepted", JSON.stringify(preferences));
    setShowBanner(false);
  };

  const toggleCustomize = () => {
    setCustomize(!customize);
  };

  const togglePreference = (category) => {
    setPreferences((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  if (!showBanner) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.banner}>
        {!customize ? (
          <>
            <p style={styles.text}>
              🍪 Ce site utilise des cookies pour améliorer votre expérience et analyser le trafic. Consultez notre{" "}
              <a href="/politique-cookies" style={styles.link}>
                politique de cookies
              </a>.
            </p>
            <div style={styles.buttons}>
              <button onClick={handleAcceptAll} style={styles.acceptButton}>
                Accepter tout
              </button>
              <button onClick={handleRefuseAll} style={styles.refuseButton}>
                Refuser tout
              </button>
            </div>
            <p style={styles.customizeText} onClick={toggleCustomize}>
              Personnaliser les cookies
            </p>
          </>
        ) : (
          <>
            <p style={styles.text}>
              Sélectionnez les catégories de cookies que vous souhaitez autoriser :
            </p>
            <div style={styles.options}>
              <label style={styles.option}>
                <input
                  type="checkbox"
                  checked={preferences.necessary}
                  disabled
                  style={styles.checkbox}
                />
                Cookies nécessaires (toujours activés)
              </label>
              <label style={styles.option}>
                <input
                  type="checkbox"
                  checked={preferences.analytics}
                  onChange={() => togglePreference("analytics")}
                  style={styles.checkbox}
                />
                Cookies d'analyse
              </label>
              <label style={styles.option}>
                <input
                  type="checkbox"
                  checked={preferences.marketing}
                  onChange={() => togglePreference("marketing")}
                  style={styles.checkbox}
                />
                Cookies marketing
              </label>
            </div>
            <div style={styles.buttons}>
              <button onClick={handleSavePreferences} style={styles.acceptButton}>
                Enregistrer
              </button>
              <button onClick={toggleCustomize} style={styles.refuseButton}>
                Retour
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    width: "auto",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    animation: "fadeIn 0.5s ease-in-out",
  },
  banner: {
    background: "white",
    borderRadius: "12px",
    maxWidth: "90%",
    width: "350px",
    padding: "20px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    animation: "slideUp 0.5s ease-out",
  },
  text: {
    fontSize: "14px",
    color: "#333",
    margin: 0,
    lineHeight: "1.5",
  },
  link: {
    color: "#007bff",
    textDecoration: "underline",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    gap: "10px",
  },
  acceptButton: {
    backgroundColor: "#28a745",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    padding: "8px 15px",
    cursor: "pointer",
    fontSize: "12px",
    fontWeight: "bold",
    flex: 1,
  },
  refuseButton: {
    backgroundColor: "#dc3545",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    padding: "8px 15px",
    cursor: "pointer",
    fontSize: "12px",
    fontWeight: "bold",
    flex: 1,
  },
  customizeText: {
    fontSize: "12px",
    color: "#007bff",
    textDecoration: "underline",
    cursor: "pointer",
    textAlign: "center",
    marginTop: "5px",
  },
  options: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  option: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    fontSize: "14px",
  },
  checkbox: {
    cursor: "pointer",
  },
  "@media (max-width: 768px)": {
    overlay: {
      bottom: "0px",
      right: "0px",
      width: "100%",
    },
    banner: {
      width: "100%",
      borderRadius: "0px",
    },
  },
};

export default CookieBanner;
