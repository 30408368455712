import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/BackgroundAsImage.js";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";
import CookieBanner from "components/cookies/CookieBanner.js";

export default () => (
  <AnimationRevealPage>
     <CookieBanner />
    <Hero />
    <MainFeature />
    <div id="services"> {/* Ajoutez un identifiant ici */}
      <Features />
    </div>
        <MainFeature2 />
    <Portfolio />
    {/* <Testimonial
      subheading="Témoignages"
      heading={
        <>
          Nos Clients <span tw="text-primary-500">Nous Aiment.</span>
        </>
      }
      description="Voici ce que certains de nos clients disent de nos services de création de site web et de SEO. Nous nous engageons à offrir la meilleure expérience en ligne pour chaque entreprise."
      
      textOnLeft={true}
    /> */}
    <FAQ
      imageSrc={customerSupportIllustrationSrc}
      imageContain={true}
      imageShadow={false}
      subheading="FAQs"
      heading={
        <>
      Vous avez des <span tw="text-primary-500">Questions ?</span>
    </>
      }
    />
    {/* <Blog /> */}
    <ContactUsForm />
    <Footer />
  </AnimationRevealPage>
);
